<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title> 404 - Seite nicht gefunden </v-card-title>
          <v-card-text>
            Die angeforderte Seite existiert nicht. <br />
            <router-link to="/"> Zurück zur Startseite </router-link>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'p404'
}
</script>

<style lang="scss" scoped>
</style>
